import Vue from 'vue';
import App from './App';
import 'amfe-flexible';
import { router } from './router/router';
import './assets/less/PotentialHouse.less'
import './components/common/common.less'
import './assets/less/SetPersonalInfo.less'
import './assets/less/decorationAudit.less'
import './assets/less/GetDoorPassword.less'
import './assets/less/GetLockPassword.less'
import './assets/less/ContactPerson.less'
import './assets/less/repairPending.less'
import './assets/less/contractDetail.less'
import './assets/less/PotentialClient.less'
import './assets/less/testForNewConttract.less'
import './assets/less/referrer.less'
import './assets/less/chooseCommunity.less'
import './assets/less/acceptanceCheckTheDetails.less'
import './assets/less/applyWithdrawal.less'
import './assets/less/wallet.less'
import './assets/less/potentialClientDetails.less'
import './assets/less/ownerQuit.less'
import './assets/less/maskLayer.less'
import './assets/less/decoratePlan.less'
import './assets/less/AddPracticalSurvey.less'
import './assets/less/doceration.less'
import './assets/less/updateProgress.less'
import './assets/less/complaintAcceptance.less'
import './assets/less/RepairDetails.less'
import './assets/less/FinancialModule.less'
import BaiduMap from 'vue-baidu-map'
import echarts from 'echarts'
import VCharts from 'v-charts'

import commonEmpty from './components/rongxun/rx-empty/index';
import loading from './components/rongxun/rx-loading/index';
Vue.use(commonEmpty)
Vue.use(loading)
Vue.use(BaiduMap,{ak:'k5K2dcSGWqcMee2QkmEjS96zSd2tnSdA'})
Vue.use(echarts)
Vue.use(VCharts)


new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
