<template>
    <div class="upload-loading">
        <!--遮罩层-->
        <van-overlay :show="loadingFlag"  z-index="999" :lock-scroll="loadingFlag" />
        <!--loading加载-->
        <van-loading v-if="loadingFlag" type="spinner" class="upload-loading" size="40px" />
    </div>
</template>

<script>
    import {Loading, Overlay} from "vant";

    export default {
        name: "loading",
        components: {
            [Loading.name]: Loading,
            [Overlay.name]: Overlay
        },
        props: {
            loadingFlag: {
                type: Boolean,
                default:true
            },

        },
    }

</script>

<style scoped>
    .upload-loading{
        position: fixed;
        z-index: 999;
        margin-top: 3rem;
        margin-left: 2.25rem;
    }

</style>